import methods from '@/enums/methods';
import Thoth from '../base/thoth';

class TefTaxService extends Thoth {
    getMerchantsTEF() {
        return this.fetch({
            method: methods.GET,
            url: '/list-merchants-tef',
        });
    }

    postTax(data) {
        return this.fetch({
            method: methods.POST,
            url: '/posting-fee-tef',
            data,
        });
    }

    getLaunchs() {
        return this.fetch({
            url: '/list-launchs',
        });
    }
}

export default new TefTaxService();
