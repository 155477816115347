import axios from 'axios';

const TIME_IN_MINUTE = 10 * 60 * 1000; // 10 minutes

const BASE_URL = process.env.VUE_APP_BASE_URL_THOTH;
const API_KEY = process.env.VUE_APP_API_KEY_THOTH;

const api = axios.create({
    baseURL: BASE_URL,

    headers: {
        'Api-Key': API_KEY,
    },
    timeout: 0,
});

export default api;
