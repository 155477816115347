<template>
  <b-container>
    <b-card :key="keyReload">
      <b-row class="justify-content-end mb-1">
        <b-button
          variant="primary"
          @click="selectAll"
        >
          Selecionar Todos
        </b-button>
        <b-col md="2">
          <b-button
            variant="primary"
            class="w-100"

            :disabled="!data.filter(item => item.check).length"
            @click="launch"
          >
            Lançar Taxa
          </b-button>
        </b-col>
      </b-row>
      <b-table
        :current-page="page"
        :items="data"
        :per-page="10"
        :fields="[
          { key: 'check', label: ''},
          'ec',
          { key: 'name', label: 'Nome'},
          'TPV'
        ]"
        class="text-center tef-table"
      >
        <template #cell(check)="data">
          <div class="d-flex justify-content-center">
            <b-form-checkbox
              v-model="data.item.check"
              switch
              class="mx-auto"
            />
          </div>
        </template>

        <template #cell(tpv)="data">
          <div class="d-flex justify-content-center">
            {{ +data.item.TPV | value }}
          </div>
        </template>
      </b-table>

      <b-pagination
        v-model="page"
        :per-page="10"
        :total-rows="data.length"
        align="center"
      />

    </b-card>
    <b-card>
      <b-table
        :current-page="page2"
        :items="logs"
        :per-page="10"
        :fields="[
          { key: 'username', label: 'Usuário'},
          'ec',
          { key: 'date', label: 'Data'}
        ]"
        class="text-center tef-table"
      >
        <template #cell(date)="data">
          {{ data.item.date | date }}
        </template>
      </b-table>

      <b-pagination
        v-model="page2"
        :per-page="10"
        :total-rows="logs.length"
        align="center"
      />
    </b-card>
  </b-container>
</template>

<script>
import {
    BTable, BCard, BPagination, BFormCheckbox, BButton, BRow, BCol, BContainer,
} from 'bootstrap-vue';
import TefTaxService from '@/service/tax/tef';

export default {
    components: {
        BTable, BCard, BPagination, BFormCheckbox, BButton, BRow, BCol, BContainer,
    },
    data() {
        return {
            page: 1,
            page2: 1,
            data: [],
            logs: [],
            keyReload: 0,
        };
    },

    async mounted() {
        this.callLoading(true);
        const { data } = await TefTaxService.getMerchantsTEF();
        const { data: logs } = await TefTaxService.getLaunchs();
        this.data = data.sort((a, b) => a.name.localeCompare(b.name));
        this.logs = logs;
        this.callLoading(false);
    },

    methods: {
        selectAll() {
            this.data.forEach(item => { console.log(item); item.check = true; });
            this.keyReload++;
        },
        async launch() {
            const responseAsk = await this.confirmAnAction('Deseja realmente lançar essas taxas?');

            if (!responseAsk) {
                return;
            }

            this.callLoading(true);

            const merchantList = this.data.filter(item => item.check).map(item => item.ec);
            const { status } = await TefTaxService.postTax({
                amount: 110,
                username: this.$store.state.user.data.name,
                merchantList,
            });
            this.callLoading(false);

            if (status === 200) {
                this.modalSuccess('Taxas lançadas com sucesso!');
            } else {
                this.modalError('Erro ao lançar taxa!');
            }
        },
    },
};
</script>

<style>

.tef-table th, [dir] .tef-table td {
  padding: 0.72rem;
  border-top: 1px solid #ebe9f1;
  width: 200px;
}
</style>
