var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('b-card',{key:_vm.keyReload},[_c('b-row',{staticClass:"justify-content-end mb-1"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.selectAll}},[_vm._v(" Selecionar Todos ")]),_c('b-col',{attrs:{"md":"2"}},[_c('b-button',{staticClass:"w-100",attrs:{"variant":"primary","disabled":!_vm.data.filter(function (item) { return item.check; }).length},on:{"click":_vm.launch}},[_vm._v(" Lançar Taxa ")])],1)],1),_c('b-table',{staticClass:"text-center tef-table",attrs:{"current-page":_vm.page,"items":_vm.data,"per-page":10,"fields":[
        { key: 'check', label: ''},
        'ec',
        { key: 'name', label: 'Nome'},
        'TPV'
      ]},scopedSlots:_vm._u([{key:"cell(check)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-form-checkbox',{staticClass:"mx-auto",attrs:{"switch":""},model:{value:(data.item.check),callback:function ($$v) {_vm.$set(data.item, "check", $$v)},expression:"data.item.check"}})],1)]}},{key:"cell(tpv)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center"},[_vm._v(" "+_vm._s(_vm._f("value")(+data.item.TPV))+" ")])]}}])}),_c('b-pagination',{attrs:{"per-page":10,"total-rows":_vm.data.length,"align":"center"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('b-card',[_c('b-table',{staticClass:"text-center tef-table",attrs:{"current-page":_vm.page2,"items":_vm.logs,"per-page":10,"fields":[
        { key: 'username', label: 'Usuário'},
        'ec',
        { key: 'date', label: 'Data'}
      ]},scopedSlots:_vm._u([{key:"cell(date)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("date")(data.item.date))+" ")]}}])}),_c('b-pagination',{attrs:{"per-page":10,"total-rows":_vm.logs.length,"align":"center"},model:{value:(_vm.page2),callback:function ($$v) {_vm.page2=$$v},expression:"page2"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }